@import "settings";

.sides-wrapper {
  display: flex;
  color: #5b5b5b;

  > * {
    float: left;
  }
  .left-side {
    width: 20%;

    ul {
      list-style: none;
      margin: 0 0.5rem 0 0;
      padding: 0;

      li {
        margin: 0;
        background-color: lighten($success-color,40%);
        border: solid 2px lighten($success-color,45%);
        border-radius: $global-radius;
        margin-bottom: 2px;
        padding: 0 0.5rem;

        a {
          color: white;
          font-weight: bold;
          font-size: 14px;
          display: block;
          line-height: 30px;
        }

        &:hover, &.active {
          background-color: lighten($success-color,30%);
        }
      }
    }
  }
  .right-side {
    width: 80%;
    min-height: 200px;
    padding: 0 50px;
  }
}