@import "settings";

.menu-wrapper {
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      float: left;
      background-color: lighten($success-color,35%);
      transition: background-color ease-out .25s;
      padding: 0 .5rem;
      -webkit-border-radius: $global-radius $global-radius 0 0;
      border-radius: $global-radius $global-radius 0 0;
      margin-right: .5rem;
      transform-origin: bottom center;

      a {
        color: white;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 10px;
        line-height: 30px;
        font-weight: bold;
      }
      &.active {
        transform: scale(1.1);
        background-color: lighten($success-color,25%);
      }
      &:hover {
        background-color: $success-color;
      }
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}