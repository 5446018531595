@import "icons";
@import "settings";
input[type="checkbox"].custom, input[type="radio"].custom {
  display: none;

  & + label {
    cursor: pointer;
  }
  & + label::before {
    height: 13px;
    width: 13px;
    display: inline-block;
    content: "\00a0";
    margin-left: -0.5rem;
    margin-right: 0.5rem;
    background-position: center;
    background-size: contain;
    line-height: 100%;
    transition: transform ease .5s;
  }
  &:not(:checked) + label::before {
    @include cross_background($alert-color);
    transform: rotateY(180deg);
  }
  &:checked + label::before {
    @include tick_background($success-color);
  }
}