.editor-wrapper {

  .custom-content-editor {
    padding: 50px 0;
    border-bottom: solid 1px rgba(0,0,0,0.25);

    &:nth-child(1) {
      padding-top: 0;
    }

  }
}