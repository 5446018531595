/*noinspection CssUnknownTarget,CssUnknownTarget,CssUnknownTarget,CssUnknownTarget*/
/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.panel {
  padding: 25px;
  background-color: white;
  border-radius: 10px;
  border: solid 1px white;
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
}

.panel.centered {
  -webkit-animation-name: fade-in-animation;
  -moz-animation-name: fade-in-animation;
  -o-animation-name: fade-in-animation;
  animation-name: fade-in-animation;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  -o-animation-timing-function: ease;
  animation-timing-function: ease;
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
    transform: translateY(calc(50vh));
  }
  to {
    opacity: 1;
    transform: translateY(calc(50vh - 50%));
  }
}
