@import "settings";

body {
  background-color: lighten($success-color, 60%);
}
.admin-wrapper {
  background-color: white;
  min-height: 100vh;
  padding: 1rem;

  .admin-header {
    height: 100px;
    position: relative;

    h1 {
      color: $primary-color;
      font-weight: bold;
      line-height: 100px;
    }

    .user-options-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px;
    }
  }

  .content-wrapper {
    width: calc(100% + 4rem);
    transform: translateX(-2rem);
    padding: 2rem;
    background-color: white;
    -webkit-box-shadow: 0 20px 20px 0 rgba(0,0,0,0.25);
    box-shadow: 0 20px 20px 0 rgba(0,0,0,0.25);
    min-height: 50vh;
  }
}