
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

//// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


@import "settings";
/*noinspection CssUnknownTarget,CssUnknownTarget,CssUnknownTarget,CssUnknownTarget*/
@import 'node_modules/foundation-sites/scss/foundation';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700';

@include foundation-everything;

.row {
  max-width: 1200px;
}